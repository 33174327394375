import React from 'react';
import { func } from 'prop-types';
import LinkToWebsite from '../LinkToWebsite';
import Signup from '../Signup';
import Login from '../Login';
import SignupClient from '../SignupClient';

const GuestNav = ({ t }) => (
  <nav className="Header__nav">
    <LinkToWebsite className="m-r-s fs-small fw-bold" t={t} />
    <SignupClient className="m-r-s" t={t} />
    <Signup className="m-r-s" t={t} />
    <Login className="m-r-s fs-small fw-bold" t={t} />
  </nav>
);

GuestNav.propTypes = {
  t: func.isRequired,
};

export default GuestNav;
