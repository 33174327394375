import { ReactComponent as Graduation } from '@/images/graduation.svg';
import { ReactComponent as Zip } from '@/images/zip.svg';
import { ReactComponent as Briefcase } from '@/images/briefcase.svg';
import { ReactComponent as File } from '@/images/file+XP.svg';
import { ReactComponent as Calendar } from '@/images/calendar.svg';
import { ReactComponent as Thesis } from '@/images/thesis.svg';

export default [
  {
    label: 'fields.missions',
    value: 'missions',
    id: 'missions',
    icon: Zip,
    'data-testid': 'missions',
  },
  {
    label: 'fields.fulltime_contract',
    value: 'fulltime_contract',
    id: 'fulltime_contract',
    icon: Briefcase,
    'data-testid': 'fulltime_contract',
  },
  {
    label: 'fields.missions_or_fulltime_contract',
    value: 'missions_or_fulltime_contract',
    id: 'missions_or_fulltime_contract',
    icon: File,
    'data-testid': 'missions_or_fulltime_contract',
  },
  {
    label: 'fields.internship',
    value: 'internship',
    id: 'internship',
    icon: Graduation,
    'data-testid': 'internship',
  },
  {
    label: 'fields.fixed_term_contract',
    value: 'fixed_term_contract',
    id: 'fixed_term_contract',
    icon: Calendar,
  },
  {
    label: 'fields.thesis',
    value: 'thesis',
    id: 'thesis',
    icon: Thesis,
  },
];
