import {
  AgricultureAgronomy,
  AeronauticsAerospace,
  ChemistryPaperTextile,
  Building,
  ComputerScienceDigital,
  Cosmetic,
  InstrumentationSensor,
  EducationSportCulture,
  Energy,
  Environment,
  IndustryManufacturing,
  MarketingCommunication,
  MaterialsCoating,
  ResearchBiology,
  SecurityDefense,
  Telecom,
  TherapeuticsMedtech,
  TransportMobility,
} from './sectors_default';

export default [
  AgricultureAgronomy,
  AeronauticsAerospace,
  ChemistryPaperTextile,
  Building,
  ComputerScienceDigital,
  Cosmetic,
  InstrumentationSensor,
  EducationSportCulture,
  Energy,
  Environment,
  IndustryManufacturing,
  MarketingCommunication,
  MaterialsCoating,
  ResearchBiology,
  SecurityDefense,
  Telecom,
  TherapeuticsMedtech,
  TransportMobility,
];
